
*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

.btn-primary{
  border: 2px solid #174a69 !important;
  color: #ffffff !important;
  background-color: #174a69 !important;
}