.mainPricing{

}

.pricing{
    padding:50px 12px;
    background-color: #ffffff;
    background-image: url(https://website-prod.cache.wpscdn.com/img/background.d16b409.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.pricing .heading{
    text-align: center;
    margin-bottom: 75px;
}
.pricing .heading h5{
    font-weight: 700;
    font-size: 40px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.pricing .heading h6{
      color: #66b47e;
      font-weight: 700;
      line-height: 40px;
      font-size: 24px;
    margin-bottom: 0;
}
.pricing .heading ul li{
    color: #00000090;
}
.pricing-card,.pricing-card.standard-plan{
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    position: relative;
    border-radius: 18px;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
}
/* .pricing-card.standard-plan{
    background: #174a69;
    border-color: #174a69;
    transform: scale(1.06);
    position: relative;
    overflow: hidden;
} */


/* .pricing-card.standard-plan:hover{
    background-color: #fff;
    border:1px solid #e9e9e9;
}

.pricing-card.standard-plan:hover h5,
.pricing-card.standard-plan:hover p,
.pricing-card.standard-plan:hover h3,
.pricing-card.standard-plan:hover span,
.pricing-card.standard-plan:hover h6 {
    color: #174a69; 
} */

/* .pricing-card.standard-plan:hover button{
    background-color: #174a69;
    color:#fff;
} */


.pricing-details-div{
    padding: 30px 24px;
}

.pricing-card.hover-effect:hover {
    background-color:#174a69; 
    color: #ffffff; 

}

.pricing-card.hover-effect:hover h5,
.pricing-card.hover-effect:hover p,
.pricing-card.hover-effect:hover h3,
.pricing-card.hover-effect:hover span,
.pricing-card.hover-effect:hover h6,
.pricing-card.hover-effect:hover button {
    color: inherit; 
}


.pricing-card.hover-effect:hover button {
    background-color: #ffffff; 
    color: #174a69; 
}

.pricing-details-div h5{
    font-weight: 700;
    font-size: 26px;
    color: #174a69;
    margin-bottom: 10px;
    border-bottom: 1px solid #174a6930;
    padding-bottom: 10px;
}

.pricing-details-div p{
    font-weight: 300;
    color: #00000090;
}
/* .standard-plan .pricing-details-div p,
.standard-plan .pricing-details-div span, .standard-plan .pricing-details-div h6{
    color: #ffffff90;
} */
.pricing-details-div h3{
    font-size: 52px;
    font-weight: 700;
    color: #174a69;
}
.pricing-details-div h3 sup{
    font-size: 26px;
    top: 1px; 
    left: 5px;
}
/* .standard-plan .pricing-details-div h5{
    border-color: #ffffff20;
} */
/* .standard-plan .pricing-details-div h5, .standard-plan .pricing-details-div h3{
    color: #ffffff;
} */
.pricing-details-div span{
    display: inline-block;
    margin-bottom: 5px;
}
.pricing-details-div button{
    width: 75%;
    background: #174a69;
}
.pricing-details-div button:hover{
    background: #174a69;
}
/* .standard-plan .pricing-details-div button, .standard-plan .pricing-details-div button:hover{
    color: #174a69;
    background: #ffffff;
} */



.pricing-box{
    position: relative;
    display: inline-block;
    max-width: 480px;
    width: 40vw;
    width: 100%;
    background: #fff;
    border-radius: 28px;
    padding: 42px 33px 50px;
    box-sizing: border-box;
    /* box-shadow: 0 24px 64px 0 rgba(121, 142, 255, .1215686275); */
    box-shadow: 0 24px 64px 0 rgb(102 180 126 / 5%);
    margin-bottom: 50px;
    border: 4px solid transparent;
    transition: .3s ease-in-out;
}

.pricing-box.popular:hover{
    transition: 100ms ease-in-out;
    background: linear-gradient(0deg, #fff, #fff), linear-gradient(163.6deg, #66b47e .28%, #174a69 51.14%, #68b280);
    border: 4px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
}

.pricing-box h3{
    gap: 6px;
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(13,13,13,.8980392157);
  line-height: 29px;
  text-align: center;

  font-weight: 700;
  font-size: 26px;
  color: #174a69;
  margin-bottom: 10px;
  border-bottom: 1px solid #174a6930;
  padding-bottom: 10px;
}

.pricing-box .price.font-title{
    margin: 29px 0 9px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #174a69;
}

.pricing-box .price .num{
    font-size: 43px;
  line-height: 43px;
  font-weight: 700;
  /* color: rgba(13,13,13,.8980392157); */
}

.pricing-box .price .num .symbol{
    font-size: 19px;
    font-weight: 700;
}

.pricing-box .price .mon{
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
}

.pricing-box .desc{
    font-size: 16px;
    color: rgba(13, 13, 13, .4588235294);
    line-height: 22px;
    height: 44px;
    padding: 0 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: center;
}

.pricing-box .pricing-select{
    margin: 6px 0 16px;
}

.pricing-box .pricing-select select{
    height: 48px;
    border: 2px solid rgba(31, 105, 224, .2);
    border-radius: 24px;
    background: hsla(0, 0%, 100%, .8);
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .2sease-in-out;
    cursor: pointer;
}

.buy-btn{
    display: inline-block;
    height: 64px;
    line-height: 30px;
    padding: 0 20px;
    font-size: 24px;
    display: flex
;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: linear-gradient(273.45deg, #174a69 -8.77%, #66b47e 47.36%, #3c4a50 111.5%);
    border-radius: 8px;
    margin: 16px 0 32px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    border: 2px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(0deg, #fff, #fff), linear-gradient(91.33deg, #66b47e, #174a69 99.73%);
    font-size: 20px;
    margin: 13px 0 29px;
    height: 54px;
}

.buy-btn span{
    background: linear-gradient(273.45deg, #66b47e -8.77%, #174a69 47.36%, #66b47e 111.5%);
    font-weight: 700;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.divider{
    border: 1px solid rgba(13,13,13,.0588235294);
    margin: 29px 0;
}

.pricing-box .rights-list{
    text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 16px;
  gap: 16px;
  color: rgba(13,13,13,.8980392157);
  opacity: 0;
  transition: max-height .4s ease,opacity .4s ease;
  overflow: hidden;
  max-height: 370px;
  opacity: 1;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pricing-box .rights-list li{
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    font-size: 14px;
    line-height: 17px;
    grid-gap: 10px;
    gap: 10px;
    color: rgba(13,13,13,.8980392157);
    text-align: left;
}

.integration .case-studio .case-studio-tabs {
    background: #ffffff;
    border: none;
    padding: 12px;
    border-radius: 10px;
    margin-bottom: 40px;
    justify-content: space-between;
}
.integration .case-studio .case-studio-tabs {
    background: #ffffff;
    border: none;
    padding: 12px;
    border-radius: 10px;
    margin-bottom: 40px;
    justify-content: space-between;
}

.integration .case-studio .case-studio-tabs .nav-item {
    margin: 0;
    padding: 0;
    width: 20%;
}

.integration .case-studio .case-studio-tabs .nav-item button.active {
    background: #66b47e;
    color: #ffffff;
    transition: .0s;
    font-weight: 600;

}


.integration .case-studio .case-studio-tabs .nav-item button {
    border: none;
    /* font-family: var(--font-familyCustom); */
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
    color: rgb(28 28 28 / 80%);
    border-radius: 10px;
    transition: .5s;
    padding: 15px 40px;
    display: block;
    margin-top: 0;
    width: 100%;
}

.integration .case-studio .case-studio-tabs .nav-item button:hover{
    background-color: #66b47e;
    color: white;
}

@media (max-width: 1700px) {
    .integration .case-studio .case-studio-tabs {
        margin-bottom: 30px;
    }
}


@media (max-width: 1700px) {
    .integration .case-studio .case-studio-tabs .nav-item button {
        font-size: 15px;
        padding: 11px 30px;
    }
}


.pool-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}



.pool-section{
    background-color: whitesmoke;
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (max-width: 1700px) {
    .pool-section .heading {
        margin-bottom: 30px;
    }
}

@media (max-width: 1700px) {
    .pool-section .heading h1 {
        font-size: 45px;
        line-height: 55px;
    }

    .pool-section .heading h5{
        font-size: 12px;
        letter-spacing: 3px;
        margin-top: 20px;
    }
}


.pool-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: grid;
    /* -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center; */
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

 .pool-box-list {
    background: #d6e3f0;
    border-radius: 10px;
    border: 1px solid #d6e3f0;
    padding: 2px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    text-align: center;
    background: #fff;
    margin: 7px 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    height: 77px;
    -webkit-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out;
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.03);
    cursor: pointer;
    transform: scale(1);
}

.pool-box-list:hover{
    transform: scale(0.9);
}
.pool-box-list a {
    background: #fff;
    color: #333;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 10px;
    position: relative;
    z-index: 99;
}

@media screen and (min-width: 992px) {
    .pool-box-list a {
        padding: 17px 23px;
    }
}

@media screen and (min-width: 992px) {
  .pool-box-list {
        -webkit-box-flex: inherit;
        -ms-flex: inherit;
        flex: inherit;
        padding: 2px;
        overflow: hidden;
        border: none;
        border: none;
        height: inherit;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

 .pool-box-list i {
    display: inline-block;
    width: auto;
}

 .pool-box-list img {
    display: block;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.pool-box-list small {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-left: 15px;
}

.pool-box-list small {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-left: 15px;
}

@media (max-width: 992px) {
    .pricing{
        padding: 30px 12px;
    }
}