.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px 12px; */
  background-color: #fff;
  position: fixed;
  width: calc(100% - 30px);
  top: 15px;
  z-index: 9;
  left: 15px;
  border-radius: 18px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  transition: 0.4s ease all;
  padding: 0;
}

.products-dropdown {
  position: relative;
}

.dropdown-toggles {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-icon {
  margin-left: 8px;
  font-size: 12px;
}

.dropdown-menu {
  position: absolute;
  top: 65px;
  left: -30px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px 0;
  margin: 0;
  min-width: 200px;
  z-index: 1000;
  display: block;
}

.dropdown-menu li {
  padding: 8px 14px;
}

.dropdown-menu li a {
  color: #66b47e !important ;
  text-decoration: none;
  display: block;
  padding: 0 !important;
  cursor: pointer;
}

.dropdown-menu li a:hover {
  /* background-color: #f5f5f5; */
  color: #40945a !important;
}

.navbar.is-sticky {
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
}

.logo a {
  font-size: 26px;
  font-weight: 300;
  text-transform: uppercase;
  color: #333;
}

.logo a span {
  font-weight: 600;
  color: #174a69;
}

.navLink ul .list-inline-item:not(:last-child) {
  margin-right: 30px;
}

.navLink ul li a {
  color: #333;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 12px;
}

.navLink ul li a svg {
  margin-right: 5px;
  transition: 0.3s ease-in-out;
}

.navLink ul li a:hover {
  color: #174a69;
}

.navLink ul li a:hover svg {
  margin-right: 0px;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  z-index: 10;
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.4s;
}

.hamburger-menu.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Responsive Styles */
@media (max-width: 992px) {
  .navLink {
    position: fixed;
    top: 70px;
    right: 25px;
    width: 250px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    display: none;
    padding: 45px;
    overflow-y: auto;
    z-index: 8;
    width: 34% !important;
    padding: 10px;
  }

  .dropdown-menu {
    top: 37px;
    left: 0px;
    width: 300px;
  }

  .navLink ul li a {
    padding: 5px 10px;
  }

  .navLink.open {
    display: block;
  }

  .navLink ul.list-inline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;
  }

  .navLink ul.list-inline .list-inline-item {
    display: block;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .hamburger-menu {
    display: block;
  }
}

.no-scroll {
  overflow: hidden;
}

@media (max-width: 992px) and (min-width: 767px) {
  .navbar {
    padding: 16px 12px;
  }
}

@media (max-width: 768px) and (min-width: 320px){
    .navLink{
        width: 320px !important;
        right: 0;
        top: 60px;
    }
}


@media (max-width: 1200px) and (min-width: 992px) {
    .logo{
        width: 20% !important;
    }

    .navLink {
        width: 80% !important;
    }

    .navLink ul li {
        margin: 0 !important;
    }

    .navLink ul li a{
      padding: 20px 9px !important;
    }

    .products-dropdown .dropdown-menu li a{
      padding: 0px 9px !important;
    }
}

@media (max-width: 1400px) and (min-width: 1200px){
    .navLink ul li {
        margin: 0 !important;
    }
}