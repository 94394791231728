@media only screen and (min-width: 320px) and (max-width: 767px) {
  .navbar {
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    padding: 12px 0;
  }
  .logo a {
    font-size: 22px;
  }
  .banner-main {
    padding: 75px 0 60px;
  }
  .banner-main h1 {
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 15px;
  }
  .banner-main ul li {
    font-size: 14px;
    line-height: 28px;
  }
  .banner-main button {
    padding: 10px 30px;
    border-radius: 6px;
    margin-top: 10px;
    font-size: 14px;
  }
  .image-section img {
    left: 0;
    margin-top: 30px;
  }
  .banner-main:after {
    display: none;
  }
  .about {
    padding: 60px 0;
  }
  .about .heading h6 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .about .heading h4 {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .about .heading {
    padding-right: 12px;
  }
  .about .heading h5 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .about .heading p {
    font-size: 14px;
  }
  .about-img.about-img-big {
    width: 50%;
    margin-left: auto;
  }
  .about-img.about-img-sm {
    left: 0;
    bottom: -30px;
    width: 60%;
    border: 6px solid #ffffff;
  }
  .play-btn-02 {
    position: absolute;
    left: 15%;
    top: 8%;
  }
  .play-btn-02 .popup-video {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .play-btn-02 .popup-video img {
    height: 24px;
  }
  .play-btn-02 .popup-video:before,
  .play-btn-02 .popup-video::after {
    width: 130px;
    height: 130px;
  }
  .products,
  .pricing,
  .integration,
  .testimonials {
    padding: 40px 0;
  }
  .product-heading {
    margin-bottom: 30px;
  }
  .integration .heading {
    text-align: center;
    margin-bottom: 30px;
  }
  .products h5,
  .pricing .heading h5,
  .heading h5,
  .benefits .heading h1,
  .testimonials .heading h5,
  .faq .heading h1,
  .pricing .heading h5 {
    font-size: 22px !important;
    margin-bottom: 10px !important;
  }
  .products p,
  .benefits .heading p,
  .heading p {
    font-size: 14px;
  }
  .productsList {
    gap: 12px;
  }
  .product-card {
    max-width: 100%;
  }
  .product-content {
    padding: 30px 12px 15px;
  }
  .pricing .heading h6 {
    font-size: 16px;
    line-height: 20px;
  }
  .pricing .heading ul li {
    font-size: 11px;
  }
  .pricing .heading,
  .benefits .heading,
  .contact .heading {
    margin-bottom: 45px;
  }
  .pricing-card {
    margin-bottom: 30px;
  }
  .pricing-card.standard-plan {
    transform: scale(1.02);
  }
  .integration-row {
    flex-direction: column-reverse;
  }
  .integration h6 {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
  }
  .integration h6 br,
  .integration .heading p br {
    display: none;
  }
  .integration .heading p {
    font-size: 14px;
  }
  .benefits,
  .faq,
  .contact {
    padding: 60px 12px;
  }
  .map-container {
    overflow: auto;
    margin-top: 45px;
  }
  .benefits-row {
    padding: 20px 0 30px;
  }
  .benefit-card {
    padding-top: 0;
  }
  .benefit-card h5 {
    font-size: 20px;
  }
  .benefit-card h5 span {
    font-size: 30px;
  }
  .benefit-card p {
    font-size: 11px;
  }
  .testimonials .heading {
    margin-bottom: 30px;
  }
  .carousel-caption {
    padding: 1rem 0 0;
  }
  .testimonials-client-details {
    width: 85%;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .carousel-caption p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .testimonials-card {
    padding: 24px 12px;
  }
  .faq-container {
    padding: 15px 12px;
  }
  .faq-question h3,
  .faq-question span {
    font-size: 16px;
  }
  .contact-detail {
    padding: 30px 12px;
    margin: 0;
  }
  .social-links .row.justify-content-between {
    justify-content: center !important;
  }
  .social-links-tag {
    margin-top: 10px;
  }
  .testimonials .carousel-control-prev {
    left: 0;
  }
  .testimonials .carousel-control-next {
    right: 0;
  }

  .pricing .heading h5 {
  }

  .benefits-row {
    background: #004a69;
  }

  .clientImagesRow {
    display: grid !important; 
    gap: 20px;
    padding-top: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .clientImagesRow div{
    text-align: center;
  }
  .clientImagesRow img {
    width: 90px;
    height: auto;
    text-align: center;
  }

  .integration.heading p{
    margin: 0px 20px;
  }

  .map-container img{
    margin: 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .banner-main:after {
    right: -50%;
  }
  .banner-main h1 {
    font-size: 24px;
    line-height: 42px;
    margin-bottom: 20px;
  }
  .banner-main ul li {
    font-size: 16px;
    line-height: 30px;
  }
  .banner-main button {
    margin-top: 10px;
    font-size: 16px;
  }
  .about,
  .products,
  .pricing,
  .integration,
  .benefits,
  .testimonials,
  .faq,
  .contact {
    padding: 60px 12px;
  }
  .faq .heading h1 {
    font-weight: 700;
  }
  .about .heading h6 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .about .heading {
    margin-bottom: 30px;
    padding-right: 12px;
  }
  .about .heading h4 {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .about .heading h5,
  .products h5,
  .pricing .heading h5,
  .heading h5,
  .benefits .heading h1,
  .testimonials .heading h5,
  .faq .heading h1,
  .pricing .heading h5 {
    font-size: 24px !important;
    margin-bottom: 15px !important;
  }
  .pricing .heading h6 {
    line-height: 30px;
    font-size: 20px;
  }
  .about .heading p,
  .products p,
  .benefits .heading p,
  .heading p {
    font-size: 15px;
  }
  .product-heading,
  .pricing .heading,
  .benefits .heading,
  .faq .heading {
    margin-bottom: 45px;
  }
  .product-card {
    max-width: 48%;
  }
  .integration h6 {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 25px;
  }
  .integration-row {
    flex-direction: column-reverse;
  }
  .integration .heading {
    text-align: left;
  }
  .benefit-card h5 {
    font-size: 18px;
  }
  .benefit-card h5 span {
    font-size: 30px;
  }
  .benefit-card p {
    font-size: 13px;
  }
  .benefit-card {
    padding-top: 0;
  }
  .benefits-row {
    padding: 30px 10px;
  }
  .testimonials-client-details {
    width: 95%;
    margin-top: 15px;
  }
  .testimonials-card {
    padding: 30px 12px;
  }
  .testimonials .heading {
    margin-bottom: 15px;
  }
  .carousel-caption {
    padding: 2rem 0;
  }
  .map-container {
    margin-top: 45px;
  }
  .contact-detail {
    padding: 48px;
  }
  .testimonials .carousel-control-prev {
    left: -24px;
  }
  .testimonials .carousel-control-next {
    right: -24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1020px) and (max-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .pricing-box h3 {
    font-size: 23px !important;
  }
}
